<template>
  <div
    v-resize="debounce(onResize, 300)"
    class="container my-24 my-lg-40"
  >
    <div class="row">
      <div class="col-12">
        <v-card class="py-32 px-16 p-xl-32">
          <div class="row">
            <div class="col">
              <v-card-button
                v-for="item in content"
                :key="item.id"
                :active="!!item.value"
                :ripple="null"
                no-gutter
                class="py-8 px-8 px-md-12 mb-8"
              >
                <div class="row align-items-center">
                  <div class="col">
                    {{ item.title }}

                    <div
                      v-if="starLineMode !== 'line'"
                      class="text-size-12"
                    >
                      {{ item.value || 0 }} / {{ item.variant.length }}
                    </div>
                  </div>

                  <div
                    v-if="starLineMode === 'line'"
                    class="col-auto col-md-2 col-lg-1 text-lg-right order-md-3"
                  >
                    {{ item.value || 0 }} / {{ item.variant.length }}
                  </div>

                  <div class="col-auto">
                    <star-line
                      v-if="starLineMode === 'line'"
                      v-model="item.value"
                      :variant="item.variant"
                      :outlined="!item.value"
                    />

                    <star-line-drop
                      v-if="starLineMode === 'dropdown'"
                      v-model="item.value"
                      size="40"
                      :variant="item.variant"
                    />

                    <star-line-replace
                      v-if="starLineMode === 'replacement'"
                      v-model="item.value"
                      :variant="item.variant"
                    />
                  </div>
                </div>
              </v-card-button>
            </div>
          </div>

          <div class="row mt-40">
            <div class="col">
              <v-button @click="onClick">
                Ответить
              </v-button>
            </div>
          </div>

          <div v-if="answer" class="row mt-40">
            <div class="col">
              {{ answer }}
            </div>
          </div>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { createCustomArray, debounce } from '@utils/helpers'
import { Resize } from '@directives'
import { VButton, VCard, VCardButton } from '@components/base'
import { StarLine, StarLineDrop, StarLineReplace } from '@components/features'

const titles = [
  'Коммуникабильность',
  'Отзывчивость',
  'Эмпатия',
  'Достаточно длинное название качества'
]

export default {
  name: 'S360Stars',

  directives: {
    Resize
  },

  components: {
    StarLine,
    StarLineDrop,
    StarLineReplace,
    VButton,
    VCard,
    VCardButton
  },

  data () {
    return {
      answer: '',
      content: createCustomArray({
        length: titles.length,
        template: i => ({
          id: i,
          isShow: true,
          title: titles[i],
          value: null,
          variant: createCustomArray({ template: i => ({ id: i }) })
        })
      }),
      starLineMode: 'line'
    }
  },

  mounted () {
    this.onResize()
  },

  methods: {
    debounce,

    onClick () {
      const answer = this.content.reduce((result, item) => `${result}, ${item.title}: ${item.value || 0}`, '')
      this.answer = answer.substring(1, answer.length)
    },

    onResize () {
      if (window.innerWidth < 576) {
        this.starLineMode = 'replacement'
      } else if (window.innerWidth < 992) {
        this.starLineMode = 'dropdown'
      } else {
        this.starLineMode = 'line'
      }
    }
  }
}
</script>
